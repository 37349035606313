import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper404 from "../components/Wrapper404/wrapper404"

const NotFoundPage = () => (
  <Layout>
    <SEO title=":(" />
    <Wrapper404/>
  </Layout>
)

export default NotFoundPage
